@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;0,800;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Body Styels */
body {
    font-family: 'Poppins', sans-serif !important;
}


/* Sidebar Styles */

.routeWrapper {
    width: 100%;
    margin: 0;
}

.socialMediaLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40px;
    position: fixed;
    bottom: 0;
    left: 30px;
}

.socialMediaLinks a{
    position: relative;
    margin: 10px 0;
    background-color: #eee;
    border-radius: 50%;
    padding: 6px 8px;
    top: 0;
    transition: all 250ms ease;
}

.socialMediaLinks svg{
    font-size: 1.2rem;
    color: #666;
    transition: all 250ms ease;
}

.socialMediaLinks a:hover {
   top: -3px;
   background-color: #FF7315;
}

.socialMediaLinks a:hover svg {
    color: white;
}

.socialMediaLinks p {
    border-left: 2px solid #666;
    height: 100px;
    margin: 10px 0 0 0;
}

.sidebar {
    height: 100vh;
    display: flex;
    align-items: center;
    position: fixed;
    right: 0;
    top: 0;
}

.sidebar ul{ 
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sidebar li {
    list-style-type: none;
    margin: 15px 0;
}

.sidebar a {
    text-decoration: none;
}

.sidebar svg {
    font-size: 1.25rem;
    margin-bottom: 4px;
    color: #666;
}

.active svg {
    color: white;
}


.homeLink {
    float: right;
    margin-right: 20px;
    position: relative;
    z-index: 10;
}

.homeBtn {
    background-color: #FF7315;
    border-radius: 50px;
    padding: 0 45px 0 12px;
    display: flex;
    align-items: center;
    left: -60px;
    bottom: 0;
    position: absolute;
    font-size: 1.1rem;
    z-index: -1;
    color: white;
    height: 50px;
    visibility: hidden;
    transition: all .25s ease-in;
}

.homeSvg{
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    border-radius: 50%;
    transition: all .15s ease-in;
}

.homeLink:hover .homeBtn {
    left: -65px;
    visibility: visible;
}

.homeLink:hover .homeSvg {
    background-color: #FF7315;
}

.homeLink:hover svg {
    color: white;
}

.active .homeSvg{
    background-color: #FF7315;
 }

.aboutLink {
    float: right;
    margin-right: 20px;
    position: relative;
    z-index: 10;
}

.aboutBtn {
    background-color: #FF7315;
    border-radius: 50px;
    padding: 0 45px 0 12px;
    display: flex;
    align-items: center;
    left: -60px;
    bottom: 0;
    position: absolute;
    font-size: 1.1rem;
    z-index: -1;
    color: white;
    height: 50px;
    visibility: hidden;
    transition: all .25s ease-in;
}

.aboutSvg{
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    border-radius: 50%;
    transition: all .15s ease-in;
}

.aboutLink:hover .aboutBtn {
    left: -65px;
    visibility: visible;
}

.aboutLink:hover .aboutSvg {
    background-color: #FF7315;
}

.aboutLink:hover svg {
    color: white;
}

.active .aboutSvg{
    background-color: #FF7315;
 }

.workLink {
    float: right;
    margin-right: 20px;
    position: relative;
    z-index: 10;
}

.workBtn {
    background-color: #FF7315;
    border-radius: 50px;
    padding: 0 45px 0 12px;
    display: flex;
    align-items: center;
    left: -53px;
    bottom: 0;
    position: absolute;
    font-size: 1.1rem;
    z-index: -1;
    color: white;
    height: 50px;
    visibility: hidden;
    transition: all .25s ease-in;
}

.workSvg{
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    border-radius: 50%;
    transition: all .15s ease-in;
}

.workLink:hover .workBtn {
    left: -58px;
    visibility: visible;
}

.workLink:hover .workSvg {
    background-color: #FF7315;
    color: white;
}

.workLink:hover svg {
    color: white;
}

.active .workSvg{
    background-color: #FF7315;
 }

.contactLink {
    float: right;
    margin-right: 20px;
    position: relative;
    z-index: 10;
}

.contactBtn {
    background-color: #FF7315;
    border-radius: 50px;
    padding: 0 45px 0 12px;
    display: flex;
    align-items: center;
    left: -80px;
    bottom: 0;
    position: absolute;
    font-size: 1.1rem;
    z-index: -1;
    color: white;
    height: 50px;
    visibility: hidden;
    transition: all .25s ease-in;
}

.contactSvg{
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    border-radius: 50%;
    transition: all .15s ease-in;
}

.contactLink:hover .contactBtn {
    left: -85px;
    visibility: visible;
}

.contactLink:hover .contactSvg {
    background-color: #FF7315;
    color: white;
}

.contactLink:hover svg {
    color: white;
}

.active .contactSvg{
    background-color: #FF7315;
 }

.mobileNav {
    position: absolute;
    left: 0;
    display: none;
}

.mobileNav button {
    border: 1px solid #c9c9c9;
    background-color: #eee;
    border-radius: 5px;
    margin: 10px 0 0 10px;
}

.mobileNav button:hover svg {
    color: #ffa115;
}

.mobileNav button:hover {
    box-shadow: 0 0 5px -1px #646464;
}

.mobileNav svg {
    font-size: 2rem;
    color: #FF7315;
}

#navCanvas {
    background-color: white;
}

.offcanvas-header {
    justify-content: flex-end !important;
}

.navCanvasBody ul {
    padding: 0;
}

.navCanvasBody li {
    list-style-type: none;
    border-bottom: 1px solid #F4F4F4 !important;
    margin-bottom: 10px;
}

.mobileLinkWrapper {
    text-decoration: none;
    display: inline-block;
}

.mobileLinkWrapper:hover p{
    color: #FF7315;
}

.mobileLinkWrapper p {
    font-size: 1.5rem;
    color: #646464;
    margin: 0;
    text-transform: uppercase;
}

.mobileLinkWrapper svg {
    margin-bottom: 5px;
    margin-right: 10px;
    color: #646464;
}

.navMediaLinksRow {
    text-align: center;
    margin: 30px 20px !important;
}

.navMediaLinksRow svg {
    font-size: 1.7rem;
    color: #FF7315;
}

.activeNav .mobileHomeLink p{
    color: #FF7315;
}

.activeNav .mobileHomeLink svg {
    color: #FF7315;
}

.activeNav .mobileAboutLink p{
    color: #FF7315;
}

.activeNav .mobileAboutLink svg {
    color: #FF7315;
}

.activeNav .mobileWorkLink p{
    color: #FF7315;
}

.activeNav .mobileWorkLink svg {
    color: #FF7315;
}

.activeNav .mobileContactLink p{
    color: #FF7315;
}

.activeNav .mobileContactLink svg {
    color: #FF7315;
}

.logoWrapper {
    position: absolute;
    right: 0;
}

.logoWrapper img {
    width: 50px;
    margin: 10px 20px 0 0
}
/* Home Styles */

.home {
    background-color: rgb(255, 255, 255);
    position: relative;
    display: flex;
}

.homeImage {
    background-image: linear-gradient(to right, #ff731588, #ffe15d9f), url(/static/media/homebackground.4a5c2fde.jpg);
    background-size: cover;
    height: 100vh;
    width: 100%;
    -webkit-clip-path: polygon(0 0, 19% 0, 60% 100%, 20% 100%, 0 20%);
            clip-path: polygon(0 0, 19% 0, 60% 100%, 20% 100%, 0 20%);
}

.intro {
    position: absolute;
    top: 35%;
    right: 50%;
    width: 500px;
    margin-right: -450px;
}

.intro h1 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #FF7315;
    transition: all 250ms ease;
}

.intro h2 {
    color: #646464;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 250ms ease;
}

.intro a {
    border: solid 2px #FF7315;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 20px;
    text-decoration: none;
    content: "";
    clear: both;
    display: table;
    text-transform: uppercase;
    position: relative;
    z-index: 10;
    overflow: hidden;
}

.intro a:hover svg {
    color: white;
}

.aboutMeSpan {
    padding: 0 10px;
    color: grey;
    vertical-align: middle;
    transition: all .250s ease-in;
}

.intro a:hover .aboutMeSpan {
    color: rgb(255, 255, 255);
}

.aboutMeSpan::after {
    z-index: -1;
    content: "";
    display: block;
    background-color: #FF7315;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 20px;
    top: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: all .250s ease-in;
}

.intro a:hover .aboutMeSpan::after{
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    box-shadow: 0 0 0 2px #FF7315;
}

.aboutMeSvg svg {
    font-size: 2rem;
    padding: 4px;
    color: #ffffff;
    border-radius: 50%;
    background-color: #FF7315;
    box-shadow: 0 0 0 1px #FF7315;
}

/* About Me Styles */

.aboutMeTitleWrapper {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
}

.aboutMeTitle {
    text-align: center;
    text-transform: uppercase;
}

.aboutMeTitle h1 {
    font-weight: 700;
    font-size: 3rem;
    color: #646464;
    margin-bottom: 0;
}

.aboutMeTitle span {
    color: #FF7315;
}

.aboutMeImg {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px !important;
}

.aboutMeImg img{
    width: 340px;
    height: 325px;
    box-shadow: -20px -20px #f4f4f3;
    border-radius: 15px;
}

.aboutMeInfo {
    display: flex;
    justify-content: flex-start;
    padding-left: 15px !important;
}

.aboutMeInfoWrapper {
    width: 400px;
    position: relative;
}

.aboutMeInfoWrapper p {
    color: #646464;
}

.aboutMeInfoWrapper h2{
    font-weight: 600;
    color: #646464;
    margin-bottom: 15px;
}

.aboutMeInfoWrapper a{
    border: solid 2px #FF7315;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 20px;
    text-decoration: none;
    content: "";
    clear: both;
    display: table;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    z-index: 10;
    overflow: hidden;
}

.aboutMeInfoWrapper a:hover .resumeDownload::after{
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    box-shadow: 0 0 0 2px #FF7315;
}

.resumeDownload {
    padding: 0 10px;
    color: grey;
    vertical-align: middle;
    transition: all .250s ease-in;
}

.resumeDownload::after {
    z-index: -1;
    content: "";
    display: block;
    background-color: #FF7315;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 20px;
    top: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: all .250s ease-in;
}

.resumeSvg svg {
    font-size: 2rem;
    padding: 4px;
    color: #ffffff;
    border-radius: 50%;
    background-color: #FF7315;
    box-shadow: 0 0 0 1px #FF7315;
}

.aboutMeInfo a:hover .resumeDownload {
    color: white;
}

.separator {
    margin: 70px auto 60px;
    width: 400px;
}

.skillsTitleWrapper {
    margin-bottom: 40px !important;
}

.skillsTitle {
    text-align: center;
    color: #646464;
}

.skillsTitle h2 {
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;
}

.skillsListWrapper {
    max-width: 1000px;
    margin: 0 auto 40px !important;
}

.skillsList {
    text-align: center;
    margin-bottom: 25px !important;
    height: 100px;
}

.skillsList svg {
    font-size: 4rem;
    padding: 10px;
    color: #FF7315;
    transition: all .10s linear;
}

.skillsList svg:hover {
    font-size: 4.5rem;
}

.skillsList h5 {
    color: #646464;
}

/* Work Styles */

.workTitleWrapper {
    margin-top: 60px !important;
    margin-bottom: 40px !important;
}

.workTitle {
    text-align: center;
    text-transform: uppercase;
}

.workTitle h1 {
    font-weight: 700;
    font-size: 3rem;
    color: #646464;
    margin-bottom: 0;
}

.workTitle span {
    color: #FF7315;
}

.workListWrapper {
    max-width: 1200px;
    margin: auto !important;
}

.workList {
    text-align: center;
    position: relative;
    padding: 15px 15px !important;
    overflow: hidden;
}

.workList img{
    width: 100%;
    height: 100%;
    border-radius: 15px;
    box-shadow: 0 0 10px -1px #e5e5e5;
}

.workList:hover .projectTitleOverlay {
    bottom: 0;
    cursor: pointer;
}

.projectTitleOverlay {
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 15px 15px;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    transition: all 300ms ease;
}

.projectTitleOverlay p {
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 600;
    border-radius: 15px;
    color: #ffffff;
    background-image: linear-gradient(to bottom right, #ff6600f0, #ffaf1bf2);
;
}

.miniappTitleWrapper {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
}

.miniappTitle {
    text-align: center;
    text-transform: uppercase;
}

.miniappTitle h2 {
    font-size: 2rem;
    color: #646464;
    margin-bottom: 0;
}

.miniappList {
    text-align: center;
}

.modalTitle {
    color: #FF7315;
    font-weight: 600 !important;
}

.modalBody {
    overflow-y: scroll;
    height: 350px !important;
    background-color: #F4F4F4;
}

.modalBody h6{
    color: #232020;
    font-weight: 600;
}

.modalImg {
    width: 100%;
    margin-bottom: 12px;
    border-radius: 15px;
}

.siteDescription p{
    font-size: .9rem;
}

.modalStackWrapper {
    width: 100%;
    margin: 0  !important;
}

.stackList {
    padding: 0 !important;
}

.stackList h6 {
    padding: 0 5px 0 0;
}

.stackList ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0 !important;
}

.stackList li{
    list-style-type: none;
    padding: 0 8px 0 0;
    font-size: .85rem;
}

.livesite {
    text-decoration: none;
    display: inline-block;
    margin-bottom: 5px;
    color: #FF7315;
    font-size: .9rem;
    font-weight: bold;
}

.livesite:hover {
    color: #646464;
}

.githubRepo {
    text-decoration: none;
    display: inline-block;
    color: #FF7315;
    font-size: 2rem;
}

.githubRepo:hover {
    color: #646464;
}

.demoLoginWrapper {
    margin-bottom: 10px;
}

.demoLoginWrapper h6{
    font-style: italic;
    font-size: .85rem;
    margin: 0;
    color: #232020;
}

.demoLoginWrapper p {
    font-style: italic;
    font-size: .8rem;
    margin: 0;
}

/* Contact Styles */

.contactTitleWrapper {
    margin-top: 60px !important;
    margin-bottom: 40px !important;
}

.contactTitle {
    text-align: center;
    text-transform: uppercase;
}

.contactTitle h1 {
    font-weight: 700;
    font-size: 3rem;
    color: #646464;
    margin-bottom: 0;
}

.contactTitle span {
    color: #FF7315;
}

.contactInfo {
    padding-right: 20px !important;
}

.contactInfo h3 {
    font-weight: 600;
    color: #646464
}

.contactInfo svg {
    color: #FF7315;
    font-size: 2rem;
    margin-bottom: 4px;
}

.contactInfo h5 {
    font-weight: 600;
    color: #646464;
    font-size: 1rem;
}

.contactForm {
    padding-left: 20px !important;
}

.contactForm button {
    border: solid 2px #FF7315;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 20px;
    text-decoration: none;
    content: "";
    clear: both;
    display: table;
    text-transform: uppercase;
    position: relative;
    z-index: 10;
    overflow: hidden;
    padding: 0;
}

.contactMeSpan {
    padding: 0 10px;
    color: grey;
    vertical-align: middle;
    transition: all .250s ease-in;
}

.contactMeSpan::after {
    z-index: -1;
    content: "";
    display: block;
    background-color: #FF7315;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 20px;
    top: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: all .250s ease-in;
}

.contactForm button:hover .contactMeSpan {
    color: rgb(255, 255, 255);
}

.contactForm button:hover .contactMeSpan::after{
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    box-shadow: 0 0 0 2px #FF7315;
}

.form-control {
    border-radius: 25px !important;
}

.form-control:focus {
    box-shadow: 0 0 3px 3px #FF7315 !important;
    border: none !important;
}

.form-label {
    font-weight: 600 !important;
    color: #646464 !important;
}

.contactMeSvg svg {
    font-size: 2.2rem;
    padding: 7px;
    color: #ffffff;
    border-radius: 50%;
    background-color: #FF7315;
    box-shadow: 0 0 0 1px #FF7315;
}

.emailSuccess {
    text-align: center;
    margin-top: 15px !important;
    -webkit-animation: slideFromBottom 500ms ease;
            animation: slideFromBottom 500ms ease;
}

.emailSuccess p{
    background-color: #FF7315;
    border-radius: 50px;
    padding: 5px 0;
    margin: 0;
    color: white;
    text-transform: uppercase;
}

@-webkit-keyframes slideFromBottom {
    0% {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 100%;
    }
}

@keyframes slideFromBottom {
    0% {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 100%;
    }
}

/* Responsiveness Styles */

@media only screen and (max-width: 500px) {
    
    .intro h1 {
        font-size: 2.5rem;
    }

    .intro h2 {
        font-size: 1.14rem;
    }

    .aboutMeSpan {
        font-size: .9rem;
    }

    .aboutMeSpan::after {
        font-size: .9rem;
    }

    /* About Page */

    .aboutMeImg img {
        width: 210px !important;
        height: 200px !important;
    }

    
}

@media only screen and (max-width: 768px) { 

    /* About Page */
    .aboutMeTitleWrapper {
        margin-top: 75px !important;
    }
    
    .aboutMeImg {
        padding: 0 !important;
    }

    .aboutMeImg img {
        margin: auto;
    }

    .aboutMeInfo {
        margin: 30px 0 !important;
        justify-content: center;
        padding: 0 20px !important;
    }

    .separator {
        width: 50%;
        margin: 40px auto 50px !important;
    }

    /* Work Page*/

    .workTitleWrapper {
        margin: 75px 0 25px 0  !important;
    }

    /* Contact Page */

    .contactTitleWrapper {
        margin: 75px 0 25px 0  !important;
    }

}

@media only screen and (max-width: 991px) {
    
    .sidebar {
        display: none;
    }

    .mobileNav {
        display: block;
    }

    .logoWrapper img {
        margin: 10px 10px 0 0;
    }

    .socialMediaLinks {
        display: none;
    }

    .homeImage {
        display: none;
    }

    .intro {
        position: inherit;
        width: 100%;
        background-image: linear-gradient(to bottom right, #ffffffe0, #ffffffeb), url(/static/media/mobileHomeImage.6da02a76.png);
        background-size: cover;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    /* About Page */

    .aboutMeImg {
        padding: 25px;
        align-items: center;
    }

    .aboutMeImg img {
        width: 300px;
        height: 275px;
    }

    .aboutMeInfoWrapper p {
        padding-bottom: 35px;
    }

    /* Contact Page */

    .contactInfo {
        padding: 0 20px !important;
        margin-bottom: 25px !important;
    }

    .contactForm {
        padding: 0 20px !important;
        margin-bottom: 25px !important;
    }
  }

  @media only screen and (min-width: 992px) {

    /* About Page */
    .aboutPageWrapper {
        padding: 0 100px !important;
    }

    /* Work Page */
    .workPageWrapper {
        padding: 0 50px !important;
    }

    /* Contact Page */
    .contactPageWrapper {
        padding: 0 75px !important;
    } 
  }
